import React, { useState } from "react"
import { Container, Card, UncontrolledAccordion, AccordionBody, AccordionItem, AccordionHeader } from"reactstrap"
import MetaTitle from "components/Shared/MetaTitle";
import Banner from "./Partial/Banner";
import AccordionRow from "./Partial/AccordionRow";
import Video from "./Partial/Video";

const routeSetup = [
  {
    title: 'Step 1: Log In to Your RouteOne Dashboard',
    description: `Begin by logging into your RouteOne dashboard. Once you're in, look at the top-left corner of the screen. You'll see your store name, and right next to it is your RouteOne Store ID. Copy that ID because you'll need it in the next step.`
  },
  {
    title: 'Step 2: Update Your Mavsign Profile',
    description: `Now, head over to your Mavsign Store profile. Paste the RouteOne Store ID into the designated field, ensuring everything is entered correctly.`
  },
  {
    title: 'Step 3: Save Your Changes',
    description: `Once you've entered the ID, click the "Save" button to secure your updates.`
  },
  {
    title: 'Step 4: Check Your Activation Status',
    description: `After saving, your account will display a "Pending Activation" status. This is part of the process. Just wait for 1 to 2 hours and then check back to see if the status changes.`
  },
  {
    title: 'Step 5: Finalize Your Sign up',
    description: `When the status updates to "Connection Active," log back into your RouteOne dashboard. From there, complete the signup process by agreeing to the E.D.P.A. Terms of Service. This step ensures everything is set up and ready to go.`
  },
  {
    title: 'Step 6: Start Sending Orders',
    description: `Congratulations! Your setup is now complete, and you are ready to send your first order from RouteOne to Mavsign. If you would like to dive deeper or learn more about this process, additional resources are available to guide you. Check out the next tutorial about submitting orders from RouteOne to Mavsign. Thank you for choosing Mavsign! `
  },
];

const howToSubmit = [
  {
    title: 'Step 1: Log In and Find Your Deal',
    description: `Start by logging into your RouteOne dashboard.  Once inside, navigate to the Dealer Manager page and locate the deal you wish to submit.`,
  },
  {
    title: 'Step 2: Export the Deal to Mavsign',
    description: `Click on the decision application and choose Export to DSP.  On the export screen, select Mavsign from the DSP options, choose the decision you'd like to export, and then click Export.`,
  },
  {
    title: 'Step 3: Access the Order in Mavsign',
    description: `Log in to your Mavsign account and go to the Orders page. You'll see your exported order in Draft status, ready to be processed.`,
  },
  {
    title: 'Step 4: Customize and Finalize the Order',
    description: `Open the draft to begin editing. From the order creation page:Select the services needed for the deal.
Verify the pre-filled customer and vehicle information for accuracy. Pay close attention to the address, if it's not accurate this can cause errors in mapping the closest notaries in the proximity of your customer.
Choose the appropriate delivery option for the order.`,
  },
  {
    title: 'Step 5: Upload Documents and Review Details',
    description: `On the Dealer Checklist page, upload any required documents for the deal.  Next, review the order summary to confirm all the information is correct.`,
  },
  {
    title: 'Step 6: Submit the Order',
    description: `Once everything is verified, click Submit to complete the process. And that's it!
      You've successfully submitted your first order from RouteOne to Mavsign. It's fast, efficient, and designed to save you time. If you have any questions or need more information, we've got you covered with additional resources. Thank you for choosing Mavsign! `,
  }
]

const RouteOne = () => {
  // Set the first accordeon open by default
 const [open, setOpen] = useState(true);

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };


  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>RouteOne</MetaTitle>
      <Container className="route-one" fluid>
        <Card className="height-100">
          <Banner />
          <div className="route-one-accordion-container mt-5">
            <UncontrolledAccordion className="mb-3" open={open} toggle={toggle} defaultOpen={['route-setup']} stayOpen >
              <AccordionItem key='route-setup'>
                <AccordionHeader targetId='route-setup'>
                  <div>
                    <h6 className="accordion-title m-0">How to Setup RouteOne:</h6>
                    <p className="accordion-description mt-3">Getting started is simple! Follow these easy steps to sign up and start sending orders from RouteOne to Mavsign.</p>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId='route-setup'>
                  <Video url={'https://www.youtube.com/embed/Z8CxCMV6uDs?si=7xKbtuCacxpLWEXu'} />
                  {routeSetup.map((routeSetupItem, index) => <AccordionRow key={index} title={routeSetupItem.title} description={routeSetupItem.description} />)}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <UncontrolledAccordion open={open} toggle={toggle} >
              <AccordionItem key='how-to-submit'>
                <AccordionHeader targetId='how-to-submit'>
                  <h6 className="accordion-title m-0">How to Submit your First RouteOne Order:</h6>
                </AccordionHeader>
                <AccordionBody accordionId='how-to-submit'>
                  <Video url={'https://www.youtube.com/embed/EMpk1rJ_i9Y?si=PnCOcIXD17k7pfvI'} />
                  {howToSubmit.map((routeSetupItem, index) => <AccordionRow key={index} title={routeSetupItem.title} description={routeSetupItem.description} />)}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
        </Card>
      </Container>
    </div>
  </React.Fragment>
}

export default RouteOne;
