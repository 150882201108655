import { route, routes } from "helpers/routeHelper";
import Dashboard from "pages/Dashboard/index";
import LeaderboardList from "pages/Dashboard/Leaderboard";
import UserList from "pages/User/List";
import UserView from "pages/User/View";
import UserNew from "pages/User/New";
import DealerGroupView from "pages/DealerGroup/View";
import DealerRegionList from "pages/DealerRegion/List";
import DealerRegionView from "pages/DealerRegion/View";
import DealerRegionNew from "pages/DealerRegion/New";
import DealerRegionNewManager from "pages/DealerRegion/New/Manager";
import DealerStoreList from "pages/DealerStore/List";
import DealerStoreView from "pages/DealerStore/View";
import DealerStoreNew from "pages/DealerStore/New";
import DealerStoreNewManager from "pages/DealerStore/New/Manager";
import DealerStoreSignerView from "pages/DealerStoreSigner/View";
import DealerStoreSignerNew from "pages/DealerStoreSigner/New";
import OrderList from "pages/Order/List";
import OrderViewIndex from "pages/Order/View";
import OrderViewVid from "pages/Order/View/Vid";
import OrderViewInkSign from "pages/Order/View/InkSign";
import OrderViewESign from "pages/Order/View/ESign";
import OrderViewFinance from "pages/Order/View/Finance";
import OrderViewSupporting from "pages/Order/View/Supporting";
import OrderViewActivity from "pages/Order/View/Activity";
import OrderViewMessages from "pages/Order/View/Messages";
import OrderNewIndex from "pages/Order/New";
import OrderNewInstructions from "pages/Order/New/Instructions";
import OrderNewCustomer from "pages/Order/New/Customer";
import OrderNewVehicle from "pages/Order/New/Vehicle";
import OrderNewContract from "pages/Order/New/Contract";
import OrderNewDocuments from "pages/Order/New/Documents";
import OrderNewConfirmation from "pages/Order/New/Confirmation";
import Seal from "pages/Order/View/Seal";
import NotificationView from "pages/Notification/View";
import CustomerCallList from "pages/CustomerCall/List";
import CustomerCallNew from "pages/CustomerCall/New";
import NotificationList from "pages/Notification/List";
import SubscriptionSetupStore from "pages/Subscription/Setup/Store";
import SubscriptionSetupPlan from "pages/Subscription/Setup/Plan";
import SubscriptionSetupPayment from "pages/Subscription/Setup/Payment";
import SubscriptionSetupPaymentConf from "pages/Subscription/Setup/PaymentConf";
import SubscriptionSetupOverview from "pages/Subscription/Setup/Overview";
import SubscriptionSetupForbidden from "pages/Subscription/Setup/Forbidden";
import SubscriptionView from "pages/Subscription/View";
import DealerStatementList from "pages/Statement/Dealer/List";
import DealerStatementView from "pages/Statement/Dealer/View";
import RedFlags from "pages/Order/New/RedFlags";
import PaymentsList from "pages/Payments/List";
import GroupUsageReport from "pages/GroupUsageReport/GroupUsage";
import DealerSalesReport from "pages/DealerSalesReport/DealerSales";
import HelpList from "pages/Help/List";
import OrderViewChannel from "pages/Order/View/Channel";
import RouteOne from "pages/RouteOne";

const publicRoutes = [

];

const protectedRoutes = [
  { path: route(routes.list_users), component: UserList },
  { path: route(routes.view_user), component: UserView },
  { path: route(routes.new_user), component: UserNew },

  { path: route(routes.view_dealer_group), component: DealerGroupView },

  { path: route(routes.list_dealer_regions), component: DealerRegionList },
  { path: route(routes.view_dealer_region), component: DealerRegionView },
  { path: route(routes.new_dealer_region), component: DealerRegionNew },
  { path: route(routes.new_dealer_region_manager), component: DealerRegionNewManager },

  { path: route(routes.list_dealer_stores), component: DealerStoreList },
  { path: route(routes.view_dealer_store), component: DealerStoreView },
  { path: route(routes.new_dealer_store), component: DealerStoreNew },
  { path: route(routes.new_dealer_store_manager), component: DealerStoreNewManager },
  { path: route(routes.new_dealer_store_signer), component: DealerStoreSignerNew },

  { path: route(routes.view_dealer_store_signer), component: DealerStoreSignerView },

  { path: route(routes.list_orders), component: OrderList },
  { path: route(routes.new_order_customer), component: OrderNewCustomer },
  { path: route(routes.new_order_vehicle), component: OrderNewVehicle },
  { path: route(routes.new_order_contract), component: OrderNewContract },
  { path: route(routes.new_order_documents), component: OrderNewDocuments },
  { path: route(routes.new_order_confirm), component: OrderNewConfirmation },
  { path: route(routes.new_order_instructions), component: OrderNewInstructions },
  { path: route(routes.view_order_vid), component: OrderViewVid },
  { path: route(routes.view_order_ink_sign), component: OrderViewInkSign },
  { path: route(routes.view_order_e_sign), component: OrderViewESign },
  { path: route(routes.view_order_finance), component: OrderViewFinance },
  { path: route(routes.view_order_supporting), component: OrderViewSupporting },
  { path: route(routes.view_order_activity), component: OrderViewActivity },
  { path: route(routes.view_order_messages), component: OrderViewMessages },
  { path: route(routes.view_order_channel), component: OrderViewChannel },
  { path: route(routes.view_order), component: OrderViewIndex },
  { path: route(routes.new_order), component: OrderNewIndex },
  { path: route(routes.view_seal), component: Seal },

  { path: route(routes.list_dealers_statements), component: DealerStatementList },
  { path: route(routes.view_dealer_statement), component: DealerStatementView },

  { path: route(routes.view_notification), component: NotificationView },
  { path: route(routes.list_notifications), component: NotificationList },

  { path: route(routes.list_customer_calls), component: CustomerCallList },
  { path: route(routes.new_customer_call), component: CustomerCallNew },

  { path: route(routes.setup_subscription_store), component: SubscriptionSetupStore, isSetupRoute: true },
  { path: route(routes.setup_subscription_plan), component: SubscriptionSetupPlan, isSetupRoute: true },
  { path: route(routes.setup_subscription_payment), component: SubscriptionSetupPayment, isSetupRoute: true },
  { path: route(routes.setup_subscription_payment_conf), component: SubscriptionSetupPaymentConf, isSetupRoute: true },
  { path: route(routes.setup_subscription_overview), component: SubscriptionSetupOverview, isSetupRoute: true },
  { path: route(routes.setup_subscription_forbidden), component: SubscriptionSetupForbidden, isSetupRoute: true },

  { path: route(routes.view_subscription), component: SubscriptionView },

  { path: route(routes.list_leaderboard), component: LeaderboardList },

  { path: route(routes.view_red_flag), component: RedFlags },

  { path: route(routes.home), component: Dashboard },

  { path: route(routes.list_payments), component: PaymentsList },

  { path: route(routes.view_group_usage_report), component: GroupUsageReport },
  { path: route(routes.view_dealer_sales_report), component: DealerSalesReport },

  { path: route(routes.list_help), component: HelpList },

  { path: route(routes.route_one), component: RouteOne },
];

export { publicRoutes, protectedRoutes }
