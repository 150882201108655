import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { Row, Form, Label, Input, FormFeedback, Button, Alert } from "reactstrap";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Col from "components/Shared/Col";
import { createOrder, doOrderFormCleanup, doOrderSingleCleanup, updateOrderCustomer } from "store/actions";
import regx from "constants/regx";
import { capitalize, getAddressComponents, nullsToEmptyStrings, phoneHasNoOfDigits, showBriefSuccess, showError, toSelectOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { route } from "helpers/routeHelper";
import Select from "react-select";
import UsStates from "constants/usState";
import { checkExistingCustomer } from "helpers/backendHelper";
import Confirmation from "components/Shared/Confirmation";
import sameCustomerIcon from "assets/images/same-customer-icon.svg";
import { useGoogleApi } from "context/google";

const FormNewCustomer = props => {

  const { id, defaultValues, navRoutes } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // google hook for autocomplete address
  const { google } = useGoogleApi();

  /********** STATE **********/

  // get redux state from the store
  const { order, isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);

  // flags that keep track of whether certain location fields need to be readonly
  // generally the user should only edit the 'address' field
  // the other location fields (city, state, zip) will be auto-filled with values returned by the Google Places api
  // however occasionally the Google Places api does not return values for all fields
  // in which case we want to allow the user to manually edit the missing fields
  const [cityIsReadonly, setCityIsReadonly] = useState(true);
  const [stateIsReadonly, setStateIsReadonly] = useState(true);
  const [zipIsReadonly, setZipIsReadonly] = useState(true);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);


  /********** FORM CONFIG **********/

  const formInitialValues = {
    dealerStoreId: '',
    customerLocation: '',
    isVidRequired: '',
    isEsignRequired: '',
    isInkSignRequired: '',
    dealershipInitials: '',
    signers: [],
    ...nullsToEmptyStrings(defaultValues, true),
    isNewOrder: !id,
  };

  const signerValidationSchema = (isNotaryRequired, index) => {
    let schema = {
      firstName: Yup.string().trim().required('Field is required').min(1, 'Field requires at least 1 character'),
      lastName: Yup.string().trim().required('Field is required').min(1, 'Field requires at least 1 character'),
      email: Yup.string().trim().required('Field is required').email('Invalid email address'),
      phone: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone', 'Field requires exactly 10 digits', (value) => phoneHasNoOfDigits(value)),
    };

    if (isNotaryRequired && index.path === "signers[0]") {
      schema = {
        ...schema,
        address: Yup.string().trim().required('Field is required'),
        city: Yup.string().when([], {
          is: () => !cityIsReadonly,
          then: Yup.string().trim().required('Field is required'),
        }),
        state: Yup.string().when([], {
          is: () => !stateIsReadonly,
          then: Yup.string().trim().required('Field is required'),
        }),
        zip: Yup.string().when([], {
          is: () => !zipIsReadonly,
          then: Yup.string().trim().required('Field is required'),
        }),
      };
    }

    return Yup.object().shape(schema);
  };

  const formValidationSchema = {
    dealerStoreId: iAmGranted(perms.view_own_group_stores) ? Yup.string().required("Field is required") : Yup.string(),
    customerLocation: Yup.number().when('isNewOrder', {
      is: true,
      then: Yup.number().required('Customer location choice is required'),
    }),
    isVidRequired: Yup.boolean().when('isNewOrder', {
      is: true,
      then: Yup.boolean().required('Identity verification choice is required'),
    }),
    isEsignRequired: Yup.boolean().when('isNewOrder', {
      is: true,
      then: Yup.boolean().required('E-sign choice is required'),
    }),
    isInkSignRequired: Yup.boolean().when('isNewOrder', {
      is: true,
      then: Yup.boolean().required('Wet-sign choice is required'),
    }),
    dealershipInitials: Yup.string().when('isNewOrder', {
      is: true,
      then: Yup.string().required('Dealership initials are required'),
    }),
    signers: Yup.array().of(
      Yup.lazy((value, index) => signerValidationSchema(defaultValues?.isNotaryRequired, index))
    ).min(1, 'Please add at least one signer'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: async values => {
      if (id) {
        // runs to check if address exists but there is no coords
        // we need this for route one orders
        if (!!values && !!values.signers?.length && values.isNotaryRequired) {
          // Is an address added but we have no coords
          if (!values.signers[0].latitude) {
            const geocoder = new google.maps.Geocoder();
            // Searching for lat/lng coords based on address
            await geocoder.geocode({
              address: values.signers[0].address,
              componentRestrictions: {
                locality: values.signers[0].city,
                administrativeArea: values.signers[0].state,
              },
            }, (results, status) => {
              if (status === 'OK' && results.length > 0) {
                // The best match is usually the first result
                const bestMatch = results[0];

                values.signers[0].latitude = bestMatch.geometry.location.lat();
                values.signers[0].longitude = bestMatch.geometry.location.lng();
              } else {
                // Showing error if no results
                formik.setFieldError('signers.0.address', "The address provided could not be verified. Please ensure the address is correct and try again")
                return;
              }
            });
          }
        }
        // some fields are used only when an order is created
        // and are NOT used when an order is updated
        // so we delete them
        delete values.customerLocation;
        delete values.isVidRequired;
        delete values.isEsignRequired;
        delete values.isInkSignRequired;
        delete values.dealershipInitials;
        dispatch(updateOrderCustomer(values, id));
      } else {
        dispatch(createOrder(values));
      }
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderFormCleanup());
    }
  }, []);

  // runs once when default values are set
  useEffect(() => {
    if (!defaultValues?.signers) {
      // this order does NOT have saved signers so add a default empty row
      formik.setFieldValue('signers', [getEmptySigner()]);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues?.signers?.length == 2) {
      formik.setFieldValue('signers[1]', {
        firstName: defaultValues?.signers[1].firstName,
        lastName: defaultValues?.signers[1].lastName,
        phone: defaultValues?.signers[1].phone,
        email: defaultValues?.signers[1].email,
      });
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      if (id) {
        showBriefSuccess('Customer information has been saved');
      } else {
        showBriefSuccess(`Order "#${order.id}" has been created`);
      }
      // multiple wizard steps use the same redux store
      // however the redirect to the next step happens before the previous component unmounts
      // which leads to the store cleanup not being done by the time the next step reads the store
      // so here we do the cleanup before the redirect
      dispatch(doOrderFormCleanup());
      dispatch(doOrderSingleCleanup());
      navigate(route(navRoutes.next, order.id));
    } else if (saved === false) {
      showError('Unable to save order');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => {
    const errors = formik.errors;
    if (!!errors[arrayName] && !!errors[arrayName][index]) {
      delete errors[arrayName][index][fieldName];
      if (fieldName === 'address') {
        // multiple location errors are linked to the 'address' field
        // so clear any location errors when the 'address' field is focused
        delete errors[arrayName][index].city;
        delete errors[arrayName][index].state;
        delete errors[arrayName][index].zip;
        delete errors[arrayName][index].latitude;
        delete errors[arrayName][index].longitude;
      }
      formik.setStatus(errors);
    }
  }

  // adds or removes the add signer fields
  const toggleAddSigner = (e, arrayHelpers) => {
    if (e.target.checked) {
      arrayHelpers.push(getEmptyAdditionalSigner());
    } else {
      arrayHelpers.remove(1);
    }
  }

  const fillInAddress = () => place => {
    const addressComponents = getAddressComponents(place);
    formik.setFieldValue(`signers.0.zip`, addressComponents.zip);
    formik.setFieldValue(`signers.0.city`, addressComponents.city);
    formik.setFieldValue(`signers.0.state`, addressComponents.state);
    formik.setFieldValue(`signers.0.address`, addressComponents.address);
    formik.setFieldValue(`signers.0.latitude`, place.geometry.location.lat());
    formik.setFieldValue(`signers.0.longitude`, place.geometry.location.lng());
    // when Google Places returns values for these fields, the user must not be allowed to edit
    // else the user must be able to add values manually
    setCityIsReadonly(!!addressComponents.city);
    setStateIsReadonly(!!addressComponents.state);
    setZipIsReadonly(!!addressComponents.zip);
  }

  const onAddressTextChanged = value => {
    // this function is called when the user types in the address field
    // which he should do only to search and not to write the actual address
    // addresses must be selected only from the autocomplete options
    // because only then we are able to extract the geo-coordinates
    // so here we invalidate any previous geo-coordinates as the user types
    // later, when he selects an address, the new geo-coordinates will be used
    formik.setFieldValue(`signers.0.address`, value);
    formik.setFieldValue(`signers.0.latitude`, '');
    formik.setFieldValue(`signers.0.longitude`, '');
  }

  /********** OTHER **********/

  // returns an empty signer object
  const getEmptySigner = () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    address2: '',
  });

  const getEmptyAdditionalSigner = () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const goNext = () => {
    const { signers } = formik.values;
    const { firstName, lastName, email, phone } = signers[0];
    checkExistingCustomer({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      orderId: id ?? null,
    }).then((response) => {
      if (!response?.isExistingCustomer) {
        formik.handleSubmit();
      } else {
        setIsConfirmationVisible(true);
      }
    })
      .catch(() => {
        formik.handleSubmit();
        showError('Unable to save order');
      })
  };

  return <React.Fragment>
    <Form className="pt-4" autoComplete="force-disable-autofill">
      <FormikProvider value={formik}>
        <FieldArray name="signers" render={arrayHelpers => <React.Fragment>
          {formik.values.signers.map((signer, index) => <div className={classnames('card-section', { 'blue pt-3 pb-1': index == 1 })} key={index}>
            <Row>
              <Col xl="6" className="mb-4">
                <Label>{index == 1 ? 'First name of additional signer' : 'First name'} *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`signers.${index}.firstName`}
                  onChange={capitalizeTextOnChange}
                  onFocus={() => onArrayFieldFocused('signers', index, 'firstName')}
                  value={formik.values.signers[index]?.firstName}
                  invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.firstName}
                />
                {!!formik.errors.signers && !!formik.errors.signers[index]?.firstName && <FormFeedback type="invalid">{formik.errors.signers[index].firstName}</FormFeedback>}
              </Col>
              <Col xl="6" className="mb-4">
                <Label>{index == 1 ? 'Last name of additional signer' : 'Last name'} *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`signers.${index}.lastName`}
                  onChange={capitalizeTextOnChange}
                  onFocus={() => onArrayFieldFocused('signers', index, 'lastName')}
                  value={formik.values.signers[index]?.lastName}
                  invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.lastName}
                />
                {!!formik.errors.signers && !!formik.errors.signers[index]?.lastName && <FormFeedback type="invalid">{formik.errors.signers[index].lastName}</FormFeedback>}
              </Col>
            </Row>
            <Row>
              <Col xl="6" className="mb-4">
                <Label>{index == 1 ? 'Phone of additional signer' : 'Cell phone'} *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`signers.${index}.phone`}
                  onChange={formik.handleChange}
                  onFocus={() => onArrayFieldFocused('signers', index, 'phone')}
                  value={formik.values.signers[index]?.phone}
                  invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.phone}
                />
                {!!formik.errors.signers && !!formik.errors.signers[index]?.phone && <FormFeedback type="invalid">{formik.errors.signers[index].phone}</FormFeedback>}
              </Col>
              <Col xl="6" className="mb-4">
                <Label>{index == 1 ? 'Email of additional signer' : 'Email'} *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`signers.${index}.email`}
                  onChange={formik.handleChange}
                  onFocus={() => onArrayFieldFocused('signers', index, 'email')}
                  value={formik.values.signers[index]?.email}
                  invalid={!!formik.errors.signers && !!formik.errors.signers[index]?.email}
                />
                {!!formik.errors.signers && !!formik.errors.signers[index]?.email && <FormFeedback type="invalid">{formik.errors.signers[index].email}</FormFeedback>}
              </Col>
            </Row>
            {(defaultValues?.isNotaryRequired && index == 0) && <React.Fragment>
              {
                !!defaultValues.r1DealJacketId &&
                <Row>
                  <Col>
                    <Alert color="primary" className='p-2 d-flex align-items-center'>
                      <div className="me-2">
                        <i className='bx bx-info-circle font-size-20'></i>
                      </div>
                      <span className="font-size-13">Please double-check the <b>customer&apos;s auto-completed address</b> before moving to the next step, to ensure it’s the <b>customer&apos;s correct address</b>. This can impact the notary assignment and the order completion time.</span>
                    </Alert>
                  </Col>
                </Row>
              }
              <Row>
                <Col xl="6" className="mb-4">
                  <Label>Address where signing will take place *</Label>
                  <PlacesAutocomplete
                    type="text"
                    className={classnames("form-control", { "is-invalid": !!formik.errors.signers && (!!formik.errors.signers[0]?.address || !!formik.errors.signers[0]?.latitude) })}
                    placeholder="ex. 2273 Muldrow Dr"
                    name={`signers.0.address`}
                    onChange={e => onAddressTextChanged(e.target.value)}
                    onPlaceChanged={fillInAddress()}
                    onFocus={() => onArrayFieldFocused("signers", 0, "address")}
                    value={formik.values.signers[0]?.address}
                  />
                  {!!formik.errors.signers && !!formik.errors.signers[0]?.address && <FormFeedback type="invalid">{formik.errors.signers[0].address}</FormFeedback>}
                  {!!formik.errors.signers && !!formik.errors.signers[0]?.latitude && <FormFeedback type="invalid" className="d-block">{formik.errors.signers[0].latitude}</FormFeedback>}
                </Col>
                <Col xl="6" className="mb-4">
                  <Label>City *</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`signers.0.city`}
                    autoComplete="force-disable-autofill"
                    onChange={capitalizeTextOnChange}
                    onFocus={() => onArrayFieldFocused('signers', 0, 'city')}
                    value={formik.values.signers[0]?.city}
                    invalid={!!formik.errors.signers && !!formik.errors.signers[0]?.city}
                    disabled={cityIsReadonly}
                  />
                  {!!formik.errors.signers && !!formik.errors.signers[0]?.city && <FormFeedback type="invalid">{formik.errors.signers[0].city}</FormFeedback>}
                </Col>
              </Row>
              <Row>
                <Col xl="6" className="mb-4">
                  <Label>State or province *</Label>
                  <Select
                    classNamePrefix="select2-selection"
                    name={`signers.0.state`}
                    autoComplete="force-disable-autofill"
                    options={usStates}
                    onChange={selected => formik.setFieldValue(`signers.0.state`, selected.value)}
                    onFocus={() => onArrayFieldFocused('signers', 0, 'state')}
                    value={usStates.find(option => option.value === formik.values.signers[0]?.state)}
                    className={!!formik.errors.signers && !!formik.errors.signers[0]?.state && 'is-invalid'}
                    isDisabled={stateIsReadonly} />
                  {!!formik.errors.signers && !!formik.errors.signers[0]?.state && <FormFeedback type="invalid">{formik.errors.signers[0].state}</FormFeedback>}
                </Col>
                <Col xl="6" className="mb-4">
                  <Label>Zip code *</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name={`signers.0.zip`}
                    autoComplete="force-disable-autofill"
                    onChange={formik.handleChange}
                    onFocus={() => onArrayFieldFocused('signers', 0, 'zip')}
                    value={formik.values.signers[0]?.zip}
                    invalid={!!formik.errors.signers && !!formik.errors.signers[0]?.zip}
                    disabled={zipIsReadonly}
                  />
                  {!!formik.errors.signers && !!formik.errors.signers[0]?.zip && <FormFeedback type="invalid">{formik.errors.signers[0].zip}</FormFeedback>}
                </Col >
              </Row >
              <Row className="mb-4">
                <Col xl="6">
                  <Label>Additional info</Label>
                  <Input
                    type="text"
                    maxLength={100}
                    placeholder="apartment, unit, suite number (max 100 characters)"
                    className="form-control"
                    name={`signers.${index}.address2`}
                    onChange={capitalizeTextOnChange}
                    onFocus={() => onArrayFieldFocused('signers', index, 'address2')}
                    value={formik.values.signers[index]?.address2}
                  />
                </Col>
              </Row>
            </React.Fragment >}
            {
              index == 0 && <Row>
                <Col className="mb-4">
                  <div className="form-check form-switch form-switch-lg d-inline-block">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="addSignerSwitch"
                      onChange={e => toggleAddSigner(e, arrayHelpers)}
                      defaultChecked={formik.values.signers.length == 2}
                    />
                    <Label className="form-check-label" htmlFor="addSignerSwitch" />
                  </div>
                  <Label className="mb-0" htmlFor="addSignerSwitch">Additional signer</Label>
                </Col>
              </Row>
            }
          </div >)}
          {
            !!formik.errors.signers && typeof formik.errors.signers === 'string' && <div className="card-section">
              <FormFeedback type="invalid" className="d-block">{formik.errors.signers}</FormFeedback>
            </div>
          }
        </React.Fragment >} />
      </FormikProvider >
      {/* Here we add error messages for the hidden fields so the user will get a visual feedback and understand why the form cannot be submitted */}
      <div className="card-section">
        {!!formik.errors.customerLocation && <FormFeedback type="invalid" className="d-block">{formik.errors.customerLocation}</FormFeedback>}
        {!!formik.errors.contractInfo && <FormFeedback type="invalid" className="d-block">{formik.errors.contractInfo}</FormFeedback>}
        {!!formik.errors.isEsignRequired && <FormFeedback type="invalid" className="d-block">{formik.errors.isEsignRequired}</FormFeedback>}
        {!!formik.errors.isInkSignRequired && <FormFeedback type="invalid" className="d-block">{formik.errors.isInkSignRequired}</FormFeedback>}
        {!!formik.errors.dealershipInitials && <FormFeedback type="invalid" className="d-block">{formik.errors.dealershipInitials}</FormFeedback>}
        <Row className="mb-2">
          <Col className="text-end pt-4">
            {navRoutes.prev && <Link to={route(navRoutes.prev, id)} className="btn btn-primary btn-faded" disabled={formik.isSubmitting}>Quit</Link>}
            <Button type="button" color="primary" className="ms-2" onClick={() => goNext()} disabled={formik.isSubmitting || isSaveInProgress}>
              {(isSaveInProgress || formik.isSubmitting) && <i className="mdi mdi-spin mdi-loading me-1" />}
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Continue"
      onConfirm={() => {
        formik.handleSubmit();
        setIsConfirmationVisible(false);
      }}
      style={{ backgroundColor: '#fff' }}
      customIcon={sameCustomerIcon}
      onCancel={() => { setIsConfirmationVisible(false); navigate(route(navRoutes.prev)); }}
      cancelBtnText="Cancel"
      reverseButtons={false}
      closeOnClickOutside={false}
    >
      <h4 style={{ color: '#556EE6', fontSize: '14px' }}><b>There are already other signings with this customer, for your dealership.</b></h4><br />
      <h4 style={{ color: '#556EE6', fontSize: '14px' }}>Please do not submit duplicate orders for the same customer. If a failed VerifyID report is generated our team will reach out to the signers to assist them with troubleshooting. A new order is not necessary.</h4><br />
      <span style={{ color: '#556EE6' }}>Are you sure you want to continue creating this order?</span>
    </Confirmation>
    }
  </React.Fragment >
}

const usStates = toSelectOptions(UsStates);

FormNewCustomer.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
};

export default FormNewCustomer;