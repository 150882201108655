import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, CardHeader, Button, CardTitle } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import CardPreloader from 'components/Shared/CardPreloader';

const ViewRouteOne = props => {

  const { isRefreshing, dealerStore, toggleEditMode } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle>Route One</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              {iAmGranted(perms.edit_dealer_stores) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
                <i className="mdi mdi-pencil me-1" />Edit
              </Button>}
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="12">
            <Row className="mb-3">
              <label className="mb-0">RouteOne Dealer ID</label>
              <div>{!!dealerStore.r1Id ? dealerStore.r1Id : "--"}</div>
            </Row>
            {!!dealerStore.r1Id &&
              <Row className="mb-3">
                <label className="mb-0">Activation Status: </label>
                <label>{!!dealerStore.isR1Enrolled ? <b className="text-success">Connection Active</b> : <b className="text-warning">Pending Activation</b>}</label>
              </Row>}
          </Col>
        </Row>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewRouteOne.propTypes = {
  isRefreshing: PropTypes.bool,
  dealerStore: PropTypes.object,
  toggleEditMode: PropTypes.func,
};

export default ViewRouteOne;