import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Card, CardBody, Form, Label, Input, FormFeedback, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import { getDealerStore } from "../../../helpers/backendHelper";
import { useSubscription } from "context/subscription";
import DealerGroup from "model/dealerGroup";
import config from "config";
import classnames from "classnames";
import { doOrderFormCleanup, doOrderSingleCleanup, updateOrderServices } from "store/actions";
import { showBriefSuccess, showError } from "helpers/utilHelper";
import { ORDER_SERVICES_ALREADY_SELECTED, ORDER_STATUS_INVALID_FOR_UPDATE_SERVICES, UnprocessableEntityException, ValidationException } from "helpers/errorHelper";
import RouteOneBanner from "components/Shared/RouteOneBanner";

const NewInstructions = () => {

  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // read query string
  const [queryParams] = useSearchParams();
  // read route params
  let { id } = useParams();
  // params are strings by default however we need ID as in int
  id = parseInt(id);

  const { dealerStore } = useSubscription();
  const [storeInfo, setStoreInfo] = useState(null);

  // check if the group special instructions exist and applies to dealers
  const isGroupSpecialInstructionsAppliesTo = () => storeInfo?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);
  const showGroupSpecialInstructions = () => storeInfo?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  /********** STATE **********/

  // get redux state from the store
  const { isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    dealershipInitials: '',
    termsAccepted: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      dealershipInitials: Yup.string().trim().required('Field is required'),
      termsAccepted: Yup.boolean()
        .oneOf([true], 'Terms and Conditions need to be accepted in order to move forward with your signing')
        .required('Terms and Conditions need to be accepted in order to move forward with your signing')
    }),
    onSubmit: values => {
      // move to the next wizard step
      // also include the query tring params collected so far
      const nextStepParams = {
        cl: queryParams.get('cl'),
        vi: queryParams.get('vi'),
        es: queryParams.get('es'),
        is: queryParams.get('is'),
        nt: queryParams.get('nt'),
        ru: queryParams.get('ru'),
        ds: queryParams.get('ds'),
        tp: queryParams.get('tp'),
        di: values.dealershipInitials,
        tc: values.termsAccepted ? 1 : 0,
      };
      if (!!id) {
        dispatch(updateOrderServices({
          ...values,
          isVidRequired: nextStepParams.vi == 1,
          isEsignRequired: nextStepParams.es == 1,
          isInkSignRequired: nextStepParams.is == 1,
          isNotaryRequired: nextStepParams.nt == 1,
          isRushRequired: nextStepParams.ru == 1,
          isAoiThumbprintRequired: nextStepParams.tp == 1,
        }, id));
      } else {
        navigate(route(routes.new_order_customer, 0, nextStepParams));
      }
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    if (!queryParams.get('cl')) {
      // user must select basic info before he can create an order
      // however there is no customer location in the query string
      // (most likely a direct access via url)
      // so redirect the user back to the first step
      navigate(route(routes.new_order));
    }
  }, []);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showBriefSuccess('Service information has been saved');
      // multiple wizard steps use the same redux store
      // however the redirect to the next step happens before the previous component unmounts
      // which leads to the store cleanup not being done by the time the next step reads the store
      // so here we do the cleanup before the redirect
      dispatch(doOrderFormCleanup());
      dispatch(doOrderSingleCleanup());
      navigate(route(routes.new_order_customer, id));
    } else if (saved === false) {
      let errMessage = 'Unable to save order';
      if (saveError instanceof ValidationException) {
        errMessage = 'Service information invalid or incomplete';
      } else if (saveError instanceof UnprocessableEntityException) {
        if (saveError.code === ORDER_STATUS_INVALID_FOR_UPDATE_SERVICES) {
          errMessage = 'Services cannot be changed at this stage';
        } else if (saveError.code === ORDER_SERVICES_ALREADY_SELECTED) {
          errMessage = 'Services have already been selected';
        }
      }
      showError(errMessage);
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // on change event handler that converts user input to uppercase letters
  const convertToUpperCaseOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, event.target.value.toUpperCase());
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/
  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store data
    refreshStoreInfo();
  }, [dealerStore?.id]);

  const refreshStoreInfo = () => {
    getDealerStore(dealerStore?.id)
      .then(response => {
        setStoreInfo(response.dealerStore);
      })
      .catch(ex => { })
  };

  const handleTermsAndConditions = event => {
    const isChecked = event.target.checked;
    formik.setFieldValue('termsAccepted', !!isChecked);

    if (!isChecked) {
      formik.setFieldValue('termsAccepted', false);
    } else if (!!isChecked && !!formik.errors.termsAccepted) {
      // hide errors if checkbox is checked
      formik.errors.termsAccepted = null;
    }
  };

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <div className="page-content">
      <MetaTitle>New order - Instructions</MetaTitle>
      <Container fluid>
        <Row>
          <Col>
            <Breadcrumbs breadcrumbItems={breadcrumbs()} />
          </Col>
          <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
            {showGroupSpecialInstructions() && <SpecialInstructionsLabel instructions={storeInfo.dealerGroupSpecialInstructions} />}
          </Col>
        </Row>
        <RouteOneBanner />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="7">
                    <div className="card-title mb-4">Instructions</div>
                    <ul className="order-instructions">
                      <li>
                        <strong>Smartphone access</strong><br />
                        Please confirm with your customer that they have access to a smartphone (capable of text messages and pictures).
                      </li>
                      <li>
                        <strong>Text message</strong><br />
                        Please inform your customer that Mavsign will send a text message (to the cell number provided in the order) and the customer will need to reply YES to consent to text messaging communication.
                      </li>
                      <div className="card-title mb-4 instruction-subtitile">Requirements for VerifyID</div>
                      <li>
                        <strong>Document needed</strong><br />
                        A US issued Drivers License or US issued Passport (US issued ID card)  will be needed from the customer to complete the VerifyID.
                      </li>
                      <li>
                        <strong>Complete the identity verification within an hour</strong><br />
                        Recommend to your customer to complete the identity verification within an hour from the message receipt or as soon as possible to prevent order delays.
                      </li>
                      <li>
                        <strong>Dealer associate cross-check</strong><br />
                        Upon the customer’s completion of the VerifyID, we recommend the dealership to compare the ID used to complete the VerifyID to the ID provided by the customer to the dealership.
                      </li>
                    </ul>
                    <Form className="pt-4">
                      <Row className="mb-2">
                        <Col xl="8">
                          <Label>Dealer Initials *</Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter dealer initials"
                            name="dealershipInitials"
                            onChange={convertToUpperCaseOnChange}
                            onFocus={onFieldFocused}
                            value={formik.values.dealershipInitials}
                            invalid={!!formik.errors.dealershipInitials}
                          />
                          {!!formik.errors.dealershipInitials && <FormFeedback type="invalid">{formik.errors.dealershipInitials}</FormFeedback>}
                        </Col>
                        <Col xl="4" className="text-end">
                          <Label>&nbsp;</Label><br />
                          <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                            {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                            Agree
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-2 mt-3">
                        <Col xl="8">
                          <div className={classnames({ 'is-invalid': !!formik.errors.termsAccepted }, 'form-check form-switch form-switch-lg d-flex align-items-center')} >
                            <Input
                              type="checkbox"
                              className="form-check-input mt-0"
                              id="termsAndConditions"
                              name="termsAccepted"
                              value={formik.values.termsAccepted}
                              onChange={e => handleTermsAndConditions(e)}
                              invalid={!!formik.errors.termsAccepted}
                            />
                            <Label className="form-check-label font-size-14 terms-of-service-label" htmlFor="termsAndConditions">
                              I agree to Mavsign
                            </Label>
                            <Button type="button" color="none" onClick={() => window.open(config.TERMS_AND_CONDITIONS_LINK, "_blank")} className="text-primary ms-1 terms-of-service-btn font-size-14">Terms and Conditions</Button>
                          </div>
                          {!!formik.errors.termsAccepted && <FormFeedback type="invalid" className="mt-1 font-size-11">{formik.errors.termsAccepted}</FormFeedback>}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>}
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'ORDERS',
  url: route(routes.list_orders),
}, {
  title: 'New order',
  url: route(routes.new_order),
}, {
  title: 'Instructions and Terms of Service',
}];

export default NewInstructions;