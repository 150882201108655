import React, { useState } from 'react';
import MetaTitle from 'components/Shared/MetaTitle';
import { Container } from 'reactstrap';
import { useAccess } from 'context/access';
import SalesManager from './Partial/SalesManager';
import FinanceManager from './Partial/FinanceManager';
import StoreManager from './Partial/StoreManager';
import RegionalManager from './Partial/RegionalManager';
import GroupManager from './Partial/GroupManager';
import Breadcrumbs from "components/Common/Breadcrumb2";
import { useAuth } from "context/auth"
import CheckInformationAlert from './Partial/CheckInformationAlert';
import BounceEmailAlert from "./Partial/BounceEmailAlert";
import YoutubeVideoModal from './Partial/Section/YoutubeVideoModal';
import RouteOneBanner from 'components/Shared/RouteOneBanner';

const Dashboard = () => {

  const { user: authUser } = useAuth();

  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(authUser.isFirstAuth);

  const {
    iAmDealerSalesManager,
    iAmDealerFinanceManager,
    iAmDealerStoreManager,
    iAmDealerRegionalManager,
    iAmDealerGroupManager,
  } = useAccess();

  let UserRoleDashboard;

  if (iAmDealerSalesManager()) {
    UserRoleDashboard = SalesManager;
  } else if (iAmDealerFinanceManager()) {
    UserRoleDashboard = FinanceManager;
  } else if (iAmDealerStoreManager()) {
    UserRoleDashboard = StoreManager;
  } else if (iAmDealerRegionalManager()) {
    UserRoleDashboard = RegionalManager;
  } else if (iAmDealerGroupManager()) {
    UserRoleDashboard = GroupManager;
  }

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>Dashboard</MetaTitle>
      <Container fluid>
        <Breadcrumbs title="DASHBOARD" />
        {!!authUser.hasEmailAlert && <BounceEmailAlert user={authUser} />}
        <RouteOneBanner />
        <CheckInformationAlert />
        {UserRoleDashboard && <UserRoleDashboard />}
      </Container>
    </div>
    <YoutubeVideoModal
      isOpen={isYoutubeModalOpen}
      closeModal={() => setIsYoutubeModalOpen(false)}
    />
  </React.Fragment>
}

export default Dashboard;
