import React from 'react';
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { route, routes } from 'helpers/routeHelper';

const RouteOneBanner = () => {
  return(<Link to={route(routes.route_one)} >
    <div className='route-one-global-banner mb-3'>
    <Row>
      <Col lg={7}>
        <h6 className='title'>You can now create orders directly from RouteOne</h6>
        <div className='description'>For this feature to work, your <strong>RouteOne Store ID</strong> is required.<br/> Please add your RouteOne Store ID in your store profile.</div>
      </Col>
      <Col className="d-flex align-items-center justify-content-center" lg={5}>
        <Button color="warning" type="submit">Get Started with RouteOne</Button>
      </Col>
    </Row>
  </div>
</Link>)
}

export default RouteOneBanner;