import { get, del, post, put } from "./apiHelper";
import { flattenFilters, withAuth } from "./utilHelper";

const getEntityList = (url, params) => get(url, { params: flattenFilters(params) });

// User

export const getAuthUser = () => get('/me');

export const impersonateUser = id => post(withAuth(`/impersonate/${id}`));

export const getUserDt = params => getEntityList('/users', params);

export const getSchedulerUserList = () => get('/users/schedulers/list');

export const getDealerUserList = () => get('users/dealers');

export const getUser = id => get(`/user/${id}`);

export const deleteUser = id => del(`/user/${id}`);

export const createUser = data => post('/user', data);

export const updateUser = (data, id) => put(`/user/${id}`, data);

export const sendVerificationEmail = userId => post(`/user/${userId}/send-verification-link`);

export const getVerificationLink = userId => get(`/user/${userId}/verification-link`);

export const getMySubscription = () => get('/me/subscription');

export const goIdle = () => put('/me/idle');

export const goActive = () => put('/me/active');

// UserRole

export const getUserRoles = () => get('/user-roles/list');

// DealerGroup

export const getDealerGroup = id => get(`/dealer-group/${id}`);

export const updateDealerGroup = (data, id) => put(`/dealer-group/${id}`, data);

export const updateDealerSpecialInstructions = (data, id) => put(`/dealer-group/${id}/special-instructions`, data);

export const getDealerGroupRegions = id => get(`/dealer-group/${id}/regions`);

export const getDealerGroupStores = id => get(`/dealer-group/${id}/stores`);

// DealerRegion

export const getDealerRegionDt = params => getEntityList('/dealer-regions', params);

export const getDealerRegion = id => get(`/dealer-region/${id}`);

export const deleteDealerRegion = id => del(`/dealer-region/${id}`);

export const createDealerRegion = data => post('/dealer-region', data);

export const updateDealerRegion = (data, id) => put(`/dealer-region/${id}`, data);

export const getDealerRegionStores = id => get(`/dealer-region/${id}/stores`);

export const getDealerRegionManagers = id => get(`/dealer-region/${id}/managers`);

// DealerStore

export const getDealerStoreDt = params => getEntityList('/dealer-stores', params);

export const getDealerStore = id => get(`/dealer-store/${id}`);

export const getDealerStores = () => get('/dealer-stores/list');

export const deleteDealerStore = id => del(`/dealer-store/${id}`);

export const createDealerStore = data => post('/dealer-store', data);

export const updateDealerStoreInfo = (data, id) => put(`/dealer-store/${id}/info`, data);

export const updateDealerStoreContact = (data, id) => put(`/dealer-store/${id}/contact`, data);

export const updateDealerStoreContact2 = (data, id) => put(`/dealer-store/${id}/contact2`, data);

export const updateDealerStoreFinance = (data, id) => put(`/dealer-store/${id}/finance`, data);

export const updateDealerStoreBilling = (data, id) => put(`/dealer-store/${id}/billing`, data);

export const updateDealerStoreR1Id = (data, id) => put(`/dealer-store/${id}/r1`, data);

export const getDealerStoreManagers = id => get(`/dealer-store/${id}/managers`);

export const getDealerStoreSigners = id => get(`/dealer-store/${id}/signers`);

export const getDealerStorePaymentPlans = id => get(`/dealer-store/${id}/payment-plans`);

export const getDealerStoreOrderCount = id => get(`/dealer-store/${id}/order-count`);

export const getDealerStoresByRegion = params => getEntityList('/dealer-stores/list-by-region', params);

// DealerStoreSigner

export const getDealerStoreSigner = id => get(`/dealer-store-signer/${id}`);

export const deleteDealerStoreSigner = id => del(`/dealer-store-signer/${id}`);

export const createDealerStoreSigner = data => post('/dealer-store-signer', data);

export const updateDealerStoreSigner = (data, id) => put(`/dealer-store-signer/${id}`, data);

// Order

export const getOrderDt = params => getEntityList('/orders', params);

export const getInProgressOrders = () => get('/orders/in-progress');

export const getOrder = id => get(`/order/${id}`);

export const submitOrder = id => put(`/order/${id}/submit`);

export const sealOrder = (id, docIds) => put(`/order/${id}/seal`, { docIds });

export const deleteOrder = id => del(`/order/${id}`);

export const cancelOrder = id => put(`/order/${id}/cancel`);

export const createOrder = data => post('/order', data);

export const updateOrderServices = (data, id) => put(`/order/${id}/services`, data);

export const updateOrderCustomer = (data, id) => put(`/order/${id}/customer`, data);

export const updateOrderVehicle = (data, id) => put(`/order/${id}/vehicle`, data);

export const updateOrderContract = (data, id) => put(`/order/${id}/contract`, data);

export const updateOrderDocs = (data, id) => put(`/order/${id}/docs/update`, data);

export const addESign = orderId => put(`/order/${orderId}/e-sign/add`);

export const removeESign = orderId => put(`/order/${orderId}/e-sign/remove`);

export const getOrderVid = (id, signer) => get(`/order/${id}/vid/${signer}`);

export const getOrderVidStatus = id => get(`/order/${id}/vid/status`);

export const getAllOrderDocs = orderId => get(`/order/${orderId}/docs`);

export const getUploadedOrderDocs = orderId => get(`/order/${orderId}/docs/uploaded`);

export const getShippedOrderDocs = orderId => get(`/order/${orderId}/docs/shipped`);

export const getInkSignOrderDocs = orderId => get(`/order/${orderId}/docs/ink-sign`);

export const getESignOrderDocs = id => get(`/order/${id}/docs/e-sign`);

export const getFinanceOrderDocs = id => get(`/order/${id}/docs/finance`);

export const getSupportingOrderDocs = id => get(`/order/${id}/docs/supporting`);

export const uploadOrderDoc = (data, orderId, config) => post(`/order/${orderId}/doc/upload`, data, config);

export const addOrderDoc = (data, orderId) => post(`/order/${orderId}/doc/add`, data);

export const getOrderInkSignStatus = id => get(`/order/${id}/ink-sign/status`);

export const getOrderESignStatus = id => get(`/order/${id}/e-sign/status`);

export const getOrderFinanceStatus = id => get(`/order/${id}/finance/status`);

export const getOrderSupportingStatus = id => get(`/order/${id}/supporting/status`);

export const getOrderSigners = (orderId, signerType) => get(`/order/${orderId}/signers/${signerType}`);

export const getOrderNotaries = orderId => get(`/order/${orderId}/notaries`);

export const getOrderVehicles = orderId => get(`/order/${orderId}/vehicles`);

export const resendCustomerNotifications = id => post(`/order/${id}/resend-customer-notifications`);

export const reloadOrderDealerSigners = id => post(`/order/${id}/reload-dealer-signers`);

export const createOrderMeeting = (orderId, role) => post(`/order/${orderId}/meet/${role}`);

export const getOrderActivity = (id, limit) => get(`/order/${id}/activity?pageSize=${limit}`);

export const getOrderMessages = (orderId, channelId, params) => getEntityList(`/order/${orderId}/messages/${channelId}`, params);

export const getAllOrderMessages = (orderId, params) => getEntityList(`/order/${orderId}/messages`, params);

export const getChatMembers = (orderId, channelId) => get(`/order/${orderId}/messages/${channelId}/participants`);

export const getOrderSignerLink = (orderId, email) => get(`/order/${orderId}/signer-link/${email}`);

export const updateOrderTrackingAndShipping = (data, id) => put(`/order/${id}/shipping`, data);

export const acceptAllInkSignDocs = orderId => put(`/order/${orderId}/docs/ink-sign/accept-all`);

export const checkExistingCustomer = (data) => get(`/order/check-existing-customer`, { params: data });

// Fedex and UPS

export const getOrderShipping = id => get(`/order/${id}/track/shipping`);

export const getOrderReturn = id => get(`/order/${id}/track/return`);

// OrderDoc

export const getOrderDoc = id => get(`/order-doc/${id}`);

export const deleteOrderDoc = id => del(`/order-doc/${id}`);

export const reprocessOrderDoc = id => post(`/order-doc/${id}/reprocess`);

export const acceptInkSignDoc = id => put(`/order-doc/${id}/ink-sign/accept`);

export const acceptESignDoc = id => put(`/order-doc/${id}/e-sign/accept`);

export const rejectESignDoc = (id, data) => put(`/order-doc/${id}/e-sign/reject`, data);

export const rejectInkSignDoc = (id, data) => put(`/order-doc/${id}/ink-sign/reject`, data);

export const acceptOrderDocPage = (orderDocId, pageNum) => put(`/order-doc/${orderDocId}/page/${pageNum}/accept`);

export const rejectOrderDocPage = (orderDocId, pageNum, data) => put(`/order-doc/${orderDocId}/page/${pageNum}/reject`, data);

export const deleteOrderDocPage = (orderDocId, pageNum) => del(`/order-doc/${orderDocId}/page/${pageNum}`);

export const addOrderDocPage = (orderDocId, beforePageNum) => post(`/order-doc/${orderDocId}/page/add` + (!!beforePageNum ? `/${beforePageNum}` : ''));

export const updateOrderDocFields = (data, id) => put(`/order-doc/${id}/fields`, data);

export const getOrderDocSigners = orderDocId => get(`/order-doc/${orderDocId}/signers`);

export const getOrderDocSignings = orderDocId => get(`/order-doc/${orderDocId}/signings`);

// VidRequest

export const getVidRequests = orderId => get(`/order/${orderId}/vid-requests`);

export const getVidRequest = id => get(`/vid-request/${id}`);

// Settings

export const refreshAppSettings = () => post('/settings/refresh');

// Customer calls

export const getCustomerCallDt = params => getEntityList('/customer-calls', params);

export const createCustomerCall = data => post('/customer-call', data);

// Dashboard

export const getOrderStats = () => get('/dashboard/order-stats');

// Leaderboard

export const getUserLeaderboard = params => getEntityList('/dashboard/user-leaderboard', params);

export const getStoreLeaderboard = params => getEntityList('/dashboard/store-leaderboard', params);

export const getCustomerPerformance = (unit, customerLocation) => get(`/dashboard/customer-performance/${unit}/${customerLocation}`);

export const getTimeToCompletion = (unit, params) => getEntityList(`/dashboard/time-to-completion/${unit}`, params)

// Notification

export const getNotificationDt = params => getEntityList('/notifications', params);

export const readManyNotifications = data => put('/notifications/read', data);

export const unreadManyNotifications = data => put('/notifications/unread', data);

export const deleteManyNotifications = data => del('/notifications', { data });

export const getNotificationList = () => getEntityList('/notifications', { page: 1, pageSize: 10, sortBy: 'id', sortDir: 'desc', });

export const getNotification = id => get(`/notification/${id}`);

export const deleteNotification = id => del(`/notification/${id}`);

export const readNotification = id => put(`/notification/${id}/read`);

export const unreadNotification = id => put(`/notification/${id}/unread`);

// PaymentPlan

export const getPaymentPlans = () => get('/payment-plans/list');

export const getPaymentPlan = id => get(`/payment-plan/${id}`);

// Payment setup

export const selectPaymentPlan = data => put('/dealer-store/select-payment-plan', data);

export const startPaymentSetup = () => post('/dealer-store/start-payment-setup');

export const initPaymentMethod = () => post('/dealer-store/init-payment-method');

export const confirmPaymentMethod = data => put('/dealer-store/confirm-payment-method', data);

export const confirmOfflinePaymentMethod = () => put('/dealer-store/confirm-offline-payment-method');

export const finishPaymentSetup = () => post('/dealer-store/finish-payment-setup');

export const cancelSubscription = () => del('/dealer-store/cancel-payment-subscription');

export const deletePaymentSetup = () => del('/dealer-store/payment-setup');

// Invoice

export const getInvoiceDt = params => getEntityList('/invoices', params);

export const getInvoice = id => get(`/invoice/${id}`);

// Statement

export const getStatementDt = params => getEntityList('/statements/dealer', params);

export const getStatement = id => get(`/statement/${id}`);


// Messages

export const getMessages = params => getEntityList('/messages', params);

export const createMessage = (data, config) => post('/message', data, config);

export const setChannelLastSeen = (orderId, channelId) => post(`/order/${orderId}/messages/${channelId}/last-seen`);

//Fees

export const getOrderFees = id => get(`/order/${id}/fees`);

export const estimateOrderFees = id => get(`/order/${id}/estimate-fees`);

// Payments

export const getPaymentDt = params => getEntityList('/payments', params);

export const getPayment = id => get(`/payments/${id}`);

export const retryPayment = id => post(`/payments/${id}/retry`);

// Report

export const getGroupUsageReport = params => get('/report/group-usage', { params })

export const getDealerSalesReport = params => get('/report/dealer-sales', { params })

// Knowledgebase

export const getServicedeskArticles = (searchTerm) => get(`/knowledgebase/articles?query=${searchTerm}`);